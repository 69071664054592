<template>
  <el-dialog
    title="匯入"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <p>請先點擊下載<span class="text-btn" @click="getExampleFile">範例</span>，填寫完後，再上傳該檔案至下方。</p>
    <div
      class="drop-zone"
      @drop.prevent="onDrop"
      @dragenter.prevent
      @dragover.prevent
      @dragleave.prevent
    >
      <div v-if="!importFile" class="flex flex-col items-center justify-center">
        <img src="@/assets/icon/cloud_upload.svg">
        <div>
          拖移上傳或是
          <label for="import-btn" class="text-btn">選取檔案</label>
          <input id="import-btn" ref="fileInput" type="file" name="import-btn" @change="fileInput">
        </div>
      </div>
      <div v-if="importFile" class="flex flex-col items-center justify-center">
        <img src="@/assets/icon/check.svg">
        <p>檔案選取成功</p>
        <div class="flex">
          <p class="text-btn">{{ importFile.name }}</p>
          <span
            class="material-icons cursor-pointer"
            style="width: 16px; height: 16px; margin-left: 10px"
            @click="importFile = null"
          >delete_filled</span>
        </div>
      </div>
    </div>
    <p>支援檔案：大小需 &#60; 5 MB，檔案格式僅支援 CSV、xlsx </p>
    <p style="margin-top: 4px">
      注意：若檔案內的兌換碼與目前存在的兌換碼發生重複或是檔案內容有錯誤時，<br>
      系統將取消本次操作不進行任何匯入。<br>
      請下載「失敗說明檔案」進行調整後再次匯入。
    </p>
    <div slot="footer" class="dialog-footer">
      <el-button plain @click="$emit('close')">取消</el-button>
      <el-button type="primary" @click="handleConfirm">確認</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { UploadFile } from '@/api/file'
import { mapGetters } from 'vuex'
import { ExportExcel } from '@/utils/excel'
import { find, get, map } from 'lodash'
import * as XLSX from 'xlsx'

export default {
  name: 'DropImportDialog',
  props: {
    examplaeFile: { type: Array, default: () => [] },
    handleSubmit: Function,
  },
  data: () => ({
    loading: false,
    importFile: null,
  }),
  computed: {
    ...mapGetters(['shop']),
  },
  methods: {
    getFile (target) {
      const input = target
      const fileData = {
        file: null,
        name: null,
        blob: null,
        raw: null,
      }
      if (!input.files) return null
      if (!input.files[0]) return null
      fileData.raw = input.files[0]
      fileData.blob = URL.createObjectURL(input.files[0])
      var reader = new FileReader()
      reader.onload = (e) => {
        fileData.file = e.target.result
      }
      reader.readAsDataURL(input.files[0])
      fileData.name = input.files[0].name
      return fileData
    },
    async uploadFile () {

    },
    onDrop (e) {
      const fileData = this.getFile(e.dataTransfer)
      this.importFile = fileData
      this.$refs.fileInput.type = 'text'
      this.$refs.fileInput.type = 'file'
    },

    fileInput (e) {
      const fileData = this.getFile(e.target)
      this.importFile = fileData
      this.$refs.fileInput.type = 'text'
      this.$refs.fileInput.type = 'file'
    },
    async handleConfirm () {
      this.loading = true
      try {
        const ab = await this.importFile.raw.arrayBuffer()
        const wb = XLSX.read(ab)
        let data
        wb.SheetNames.forEach((n, idx) => {
          const ws = wb.Sheets[n]
          const res = XLSX.utils.sheet_to_json(ws, { header: 1 })
          data = map(res, r => r[0])
        })
        data.shift()
        data = data.filter(i => i)
        console.log(data)

        const res = await this.handleSubmit(data)
        if (res.isError) this.$emit('error', { errors: res.results, codes: data })
        if (!res.isError) this.$emit('success', res.results)
        this.$emit('close')
      } catch (error) {
        console.log(error)
        this.loading = false
        window.$message.error(error)
      }
    },

    async getExampleFile () {
      const data = this.examplaeFile
      ExportExcel(data, '範例', '範例')
    },
  },
}
</script>

<style lang="postcss" scoped>
.drop-zone {
  @apply flex flex-col justify-center items-center h-[187px];
  @apply border border-primary-100 border-dashed rounded-[4px] my-[16px];
}

.text-btn {
  @apply underline text-primary-100 cursor-pointer;
}

#import-btn {
  @apply hidden;
}
</style>
