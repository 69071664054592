<template>
  <div class="test">
    <button @click="show = !show">Toggle</button>
    <router-link to="/">csacac</router-link>
    <SerialcodeImportModal
      v-if="show"
      @close="show = false"
    />
  </div>
</template>

<script>
import SerialcodeImportModal from '@/components/SerialCode/SerialcodeImportModal.vue'
import { defineComponent, ref, onMounted } from 'vue'
import { useWindowUnload } from '@/use/useWindowUnload'

export default defineComponent({
  name: 'Test',
  components: { SerialcodeImportModal },
  setup (props, { emit }) {
    const show = ref(false)
    const { registerWarnUnloadEvent } = useWindowUnload()

    registerWarnUnloadEvent()

    const handleSubmit = async (formData, { emit }) => {
      // emit('close')
    }

    return { show, handleSubmit }
  },
})
</script>

<style lang="postcss" scoped>

</style>
